import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContactForm from '../components/ContactForm'
import Img from "gatsby-image";
import HowItWorks from "../components/HowItWorks";
import AgentvsSellFastSactable from '../components/AgentvsSellFastSacTable'
import brandLogos from "../settings/company_brand.json";

const ComparePage = () => {
  const process = [
    { desc: "Reach out and tell us about your house or land, your situation, and your selling goal" },
    { desc: "Right away, we will begin our research on your property and recent sales in your area" },
    { desc: "As quickly as possible, we will provide you with an offer and valuable information to help you determine how to sell" },
    { desc: "If you choose to sell your house or land directly, we will work according to your timeframe and can be ready in just 7 days" }
  ];

  return (
    <Layout headerTransparent={false}>
      <SEO
        title={'Need To Sell Your House or Land in Sacramento? Compare Your Options'}
        description={'Need to sell your house or land in Sacramento? Hiring a local agent may not be your best option. Learn more about your choices before making a commitment.'}
        keywords={''}
      />

      <section className="py-16 section-stripe-bg bg-gray-50 ">
        <div className="flex flex-wrap px-6 md:px-8 container mx-auto ">
          <div className="w-full md:w-2/3 md:pr-4 prose">
            <h1>Should You Hire a Sacramento Real Estate Agent or Sell Your House or Land Directly?</h1>
            <p>When making the decision to sell your house or land in Sacramento, it’s important to learn about the options available to you. Many people think that listing with a local agent is their only option, however, there are other ways to get the job done. At Sell Your Home For Cash Sacramento, we want to buy your house or land outright, closing in just 7 days. Take a look at some of the numbers below and reach out to our team if you have any questions! 916-237-9457</p>

            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th></th>
                  <th width="40%">Hiring A Sacramento Real Estate Agent</th>
                  <th width="40%">
                    <div
                      style={{ width: "210px" }}
                      className="font-bold text-lg font-display mb-2"
                    >
                      {brandLogos.companylogo ? (
                        <div
                          className="w-full"
                          dangerouslySetInnerHTML={{
                            __html: brandLogos.companylogo,
                          }}
                        ></div>
                      ) : brandLogos.companylogodark ? (
                        <img src={brandLogos.companylogodark} className="w-full" />
                      ) : null}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong>Commissions / Fees:</strong></td>
                  <td>Sellers can expect to pay about 6% in commissions on average</td>
                  <td>There are never any commissions or fees</td>
                </tr>
                <tr>
                  <td><strong>Who Pays Closing Costs?:</strong></td>
                  <td>You can expect to pay about 1-3% in closing costs</td>
                  <td>We pay all closing costs</td>
                </tr>
                <tr>
                  <td><strong>Inspections and Contingencies:</strong></td>
                  <td>Required by lenders, causing up to 15% of sales to <a rel="noopener noreferrer" href="http://www.trulia.com/blog/5-reasons-home-sales-fall-prevent/" target="_blank">fall through</a></td>
                  <td>Not required</td>
                </tr>
                <tr>
                  <td><strong>Appraisal Required:</strong></td>
                  <td>Also required by lenders before funding a loan</td>
                  <td>Not required</td>
                </tr>
                <tr>
                  <td><strong>Number of Showings:</strong></td>
                  <td>Unknown</td>
                  <td>None</td>
                </tr>
                <tr>
                  <td><strong>Closing Date:</strong></td>
                  <td>It can take 1-2 months for the escrow process to complete</td>
                  <td>We will work on your timeframe</td>
                </tr>
                <tr>
                  <td><strong>Who Pays For Repairs?:</strong></td>
                  <td>Negotiated between the buyer and the seller after the inspection has been completed</td>
                  <td>We will pay for all of the needed repairs</td>
                </tr>
              </tbody>
            </table>

            <h2>Run the Numbers and Make the Choice That is Right for You! </h2>

            <p>Every property and situation are different. For some sellers, working with a Sacramento real estate agent is a great choice. However, for many others, it ultimately costs more time and money than they had expected.</p>

            <p>We want to help you run the numbers for your house or land in Sacramento so that you can make the selling decision that works best for you! If you decide to sell directly to Sell Your Home For Cash Sacramento, here are just a few of the perks you will receive… </p>

            <h3>A Fast Sale Which Can Save You Thousands… </h3>

            <p>Owning a property can cost a lot of money. Utility bills, taxes, insurance, maintenance, and maybe even a mortgage payment can set you back thousands of dollars each month. If your property sits on the market for a long time, this can equate to thousands of dollars you could have kept in your pocket. </p>

            <h3><strong>No Cleaning, Showing, Staging, or Repairs</strong></h3>

            <p>When you list your house or land in Sacramento, you’ll need to get it ready for the MLS. This may mean cleaning up, staging, landscaping, or making repairs. When you sell to Sell Your Home For Cash Sacramento, you will be able to sell as-is. We will pay you a great price no matter the condition of your property. </p>

            <h3><strong>There Are Never Any Commissions, Closing Costs, or Hidden Fees… </strong></h3>

            <p>With a direct sale to Sell Your Home For Cash Sacramento, you will not have to spend a dime. We do not charge commissions and we will even pick up the closing costs. Our goal is to pay you a fair price while offering the most straightforward and convenient way to sell your house or land in Sacramento. </p>

            <p>Depending on your property and situation, after you run the numbers, you may find a direct sale is the best way to go! We can’t pay full retail, but our prices are more than fair! Plus, the amount of time and money we are able to save you can more than make up for it! </p>

            <h4 class="has-text-align-center">Find Out If A Direct Sale Is Right For You! </h4>

            <p>Before you hire an agent to help you sell your house or land in Sacramento, reach out to our team! We will run the numbers with you, making you an offer that is honest and fair. Fill out the form below to send us a message or give us a call today! </p>
          </div>

          <div className="w-full md:w-1/3 md:pl-4">
            <div className="rounded-xl mb-2 shadow bg-white mt-6 md:mt-0">
              <div className="px-8 pt-8">
                <h2 className="text-gray-800 font-bold text-2xl md:text-3xl leading-8">Get Your Fair Cash Offer: Start Below!</h2>
                <p class="text-gray-600 mb-2 leading-6 mt-1">We buy houses and land in any condition — no commissions or fees and no obligation whatsoever. Start below by giving us a bit of information about your property or call 916-237-9457.</p>
              </div>
              <div className="p-8 pt-4">
                <ContactForm stacked={true} hideLabels={true} showPlaceholders={true} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <HowItWorks />
    </Layout>
  );
};

export default ComparePage;
